import {
  EXCEPTION_ERROR,
  FORGOT_USER_PASSWORD_SUCCESS,
  GET_GDRIVE_DETAILS_SUCCESS,
  GET_MENU_ITEMS_SUCCESS,
  GET_USERS_SUCCESS,
  GET_USER_DETAILS_SUCCESS,
  LOADING_START,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  POST_ADMIN_ROLE_ASSIGNMENT_DETAILS_SUCCESS,
  POST_GDRIVE_DETAILS_SUCCESS,
  POST_GDRIVE_SERVICE_SUCCESS,
  POST_USER_DETAILS_SUCCESS,
  RESET_EXCEPTION_ERROR,
  SESSION_API_ERROR,
  SESSION_EXPIRE_ERROR,
  UPDATE_USER_PASSWORD_SUCCESS,
} from "../actions/actionTypes";

const initialState = {
  formSubmitted: false,
  userDetails: [],
  isLoggedin: false,
  isSessionLive: false,
  isSessionAPIError: false,
  isInternalServerError: false,
  isSessionExpiry: false,
  showLoading: false,
  isPostAdminRole: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_START:
      return {
        ...state,
        showLoading: true,
      };
    case SESSION_EXPIRE_ERROR:
      return {
        ...state,
        isSessionExpiry: true,
        showLoading: false,
      };
    case SESSION_API_ERROR:
      return {
        ...state,
        apiError: action.apiError,
        showLoading: false,
        isSessionAPIError: true,
        isInternalServerError: false,
      };
    case EXCEPTION_ERROR:
      return {
        ...state,
        ISError: action.ISError,
        showLoading: false,
        isInternalServerError: true,
      };
    case RESET_EXCEPTION_ERROR:
      return {
        ...state,
        ISError: action.ISError,
        showLoading: false,
        isInternalServerError: false,
        isSessionAPIError: false,
      };
    case LOGIN_SUCCESS:
      localStorage.clear();
      localStorage.setItem("userDetails", JSON.stringify(action.userDetails));
      return {
        ...state,
        userDetails: action.userDetails,
        submitted: action.submitted,
        isLoginFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        fetchUserLogout: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_USER_DETAILS_SUCCESS:
      return {
        ...state,
        fetchGetUserDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_USER_DETAILS_SUCCESS:
      return {
        ...state,
        fetchPostUserData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_MENU_ITEMS_SUCCESS:
      return {
        ...state,
        fetchGetMenuItemsDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        fetchGetUsersDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_ADMIN_ROLE_ASSIGNMENT_DETAILS_SUCCESS:
      return {
        ...state,
        isPostAdminRole: true,
        fetchPostAdminRoleAssingmentData: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case "RESET_ROLE_ASSIGNMENT":
      return initialState;
    case GET_GDRIVE_DETAILS_SUCCESS:
      return {
        ...state,
        fetchGetGdriveDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_GDRIVE_DETAILS_SUCCESS:
      return {
        ...state,
        fetchPostGdriveDetails: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case POST_GDRIVE_SERVICE_SUCCESS:
      return {
        ...state,
        fetchPostGdriveService: action.response,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case UPDATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        fetchuserUpdatePasswordDetails: action.response,
        isUpdatePasswordFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    case FORGOT_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        fetchuserForgotPasswordDetails: action.response,
        isForgotPasswordFetched: true,
        isSessionLive: true,
        isSessionExpiry: false,
        isSessionAPIError: false,
        isInternalServerError: false,
        showLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;

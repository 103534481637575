import React from "react";
import { Link, NavLink, Outlet } from "react-router-dom";
import "./DashboardComponent.css";
import RoleManagement from "../assets/images/role_management.png";
import UserMangement from "../assets/images/user_management.png";

export const DashboardComponent = ({ userContent, roleContent }) => {
  return (
    <div className="dashboard-container">
      <div className="dashboard-card-container">
        <DashboardCard
          link="/usermanagement"
          img={UserMangement}
          name="User Management"
          text="Add or Inactive users"
        />
      </div>
      <div className="dashboard-card-container">
        <DashboardCard
          link="/rolemanagement"
          img={RoleManagement}
          name="Role Management"
          text="Assing Role Permissions to user"
        />
      </div>
    </div>
  );
};

export const DashboardCard = ({ img, name, link, text }) => {
  return (
    <>
      <Link to={link} className="custom-dashboard-link">
        <div className="dashboard-card-img">
          <img src={img} />
        </div>
        <div className="dashboard-card-name"> {name}</div>
        <div className="dashboard-card-text">{text} </div>
      </Link>
    </>
  );
};
